<template>
  <footer class="relative bg-gray-400 pt-8 pb-6 px-3">
    <div class="container mx-auto max-w-screen-lg">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 mb-1">
          <h4 class="text-3xl font-semibold">Let's keep in touch!</h4>
          <h5 class="text-lg mt-0 mb-2 text-gray-700">
            Find us on any of these networks.
          </h5>
          <div class="my-3">
            <button
              class="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a href="https://www.linkedin.com/company/66325688">
                <i class="fab fa-linkedin"></i
              ></a></button
            ><button
              class="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a href="https://www.facebook.com/graduatedio-317354168936402">
                <i class="fab fa-facebook-square"></i
              ></a>
            </button>
          </div>
        </div>
        <div class="w-full lg:w-6/12 ">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span
                class="block uppercase text-gray-600 text-sm font-semibold mb-2"
                >Useful Links</span
              >
              <ul class="list-unstyled">
                <li>
                  <router-link
                    class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    to="/about-us"
                    >About Us</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    to="/contact-us"
                    >Contact Us</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    to="/terms"
                    >Terms &amp; Conditions</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    to="/privacy"
                    >Privacy Policy</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap my-8 items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-gray-600 font-semibold pb-8">
            Copyright © {{ date }}
            <router-link to="/">graduated.io</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
