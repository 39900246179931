import { SHOW_ALERT } from "./actions.type";
import { PURGE_ALERT, SET_ALERT } from "./mutations.type";

const state = {
  isVisible: false,
  message: "",
};

const getters = {
  alertVisible(state) {
    return state.isVisible;
  },
  alertMessage(state) {
    return state.message;
  },
};

const mutations = {
  [SET_ALERT](state, payload) {
    state.isVisible = true;
    state.message = payload.message;
  },
  [PURGE_ALERT](state) {
    state.isVisible = false;
    state.message = "";
  },
};

const actions = {
  async [SHOW_ALERT](context, params) {
    context.commit(SET_ALERT, { message: params.message });
    setTimeout(() => {
      context.commit(PURGE_ALERT);
    }, 10000);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
