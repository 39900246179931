var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-full fixed top z-20",
      staticStyle: { "background-color": "#44B244" }
    },
    [
      _c(
        "nav",
        {
          staticClass:
            "flex items-center justify-between flex-wrap mx-auto max-w-screen-lg p-1"
        },
        [
          _c(
            "div",
            { staticClass: "flex items-center flex-no-shrink text-white mr-6" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white",
                  attrs: { to: "/" }
                },
                [
                  _c("img", {
                    attrs: { width: "100", src: "/logos/graduated.png" }
                  })
                ]
              )
            ],
            1
          ),
          !_vm.isAuthenticated
            ? _c("div", { staticClass: "block sm:hidden" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "flex items-center px-3 py-2 border rounded text-white border-teal-light hover:text-white hover:border-white",
                    on: { click: _vm.toggle }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "fill-current h-3 w-3",
                        attrs: {
                          viewBox: "0 0 20 20",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("title", [_vm._v("Menu")]),
                        _c("path", {
                          attrs: {
                            d: "M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          !_vm.isAuthenticated
            ? _c(
                "div",
                {
                  staticClass: "w-full sm:flex sm:items-center sm:w-auto",
                  class: _vm.open ? "block" : "hidden"
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-sm sm:flex-grow" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "no-underline block mt-4 sm:inline-block sm:mt-0 text-white hover:text-white mr-4",
                          attrs: { to: "/login" }
                        },
                        [_vm._v(" Login ")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "no-underline block mt-4 sm:inline-block sm:mt-0 text-white hover:text-white mr-4",
                          attrs: { to: "/signup" }
                        },
                        [_vm._v(" Signup ")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "no-underline inline-block sm:inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-gray-900 hover:bg-white mt-4 sm:mt-0",
                          attrs: { to: "/join" }
                        },
                        [_vm._v(" For Employers ")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.isAuthenticated
            ? _c("div", { staticClass: "group inline-block relative" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "text-white font-semibold py-2 px-4 rounded inline-flex items-center hover:bg-gray-700 hover:text-white"
                  },
                  [
                    _c("span", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.currentUserGivenName))
                    ]),
                    _c(
                      "svg",
                      {
                        staticClass: "fill-current h-4 w-4",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticClass:
                      "absolute hidden text-gray-700 pt-1 group-hover:block right-0 z-20"
                  },
                  [
                    _c("li", { staticClass: "rounded" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap",
                          attrs: { href: "#" },
                          on: { click: _vm.signOut }
                        },
                        [_vm._v("Sign out")]
                      )
                    ])
                  ]
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }