import { Amplify } from "@aws-amplify/core";
import Analytics, { AWSKinesisFirehoseProvider } from "@aws-amplify/analytics";
import awsExports from '@/lib/aws-exports';

Amplify.Logger.LOG_LEVEL = 'DEBUG';

console.log(JSON.stringify(awsExports));

Analytics.addPluggable(new AWSKinesisFirehoseProvider());
Amplify.configure(awsExports);
