<template>
  <div class="min-h-screen bg-white">
    <!--<Alert />-->
    <Navbar />
    <!--<Sidebar />-->
    <div class="flex flex-col flex-1 pt-12">
      <slot />
    </div>
  </div>
</template>

<script>
//import Alert from "@/components/Alert";
import Navbar from "@/components/Navbar";
//import Sidebar from "@/components/Profile/Sidebar";

export default {
  name: "AppLayout",
  components: {
    //Alert,
    Navbar,
    //Sidebar,
  },
};
</script>
