import gql from "graphql-tag";

export const listings = gql`
  query getListings {
    listings {
      name
      message
      slug
      skills
    }
  }
`;

export const getCompany = gql`
  query getCompany($slug: String!) {
    company(slug: $slug) {
      name
      message
      slug
      content
      blurb
    }
  }
`;

export const getProfile = gql`
  query getProfile($input: String!) {
    profile(input: $input) {
      firstName
      lastName
      availableFrom
      canContact
      createdAt
      summary
      title
      education {
        educationId
        startYear
        university
        degree
        field
        endYear
      }
    }
  }
`;

export const lookupUniversity = gql`
  query getLookupUniversity($input: String!) {
    lookupUniversity(input: $input) {
      name
      country
    }
  }
`;
