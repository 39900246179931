var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.alertVisible
    ? _c("div", { staticClass: "fixed w-full z-20" }, [
        _c("div", { staticClass: "relative" }, [
          _c(
            "div",
            {
              staticClass:
                "items-center bg-orange-500 h-12 text-white text-sm font-bold px-4 py-3",
              attrs: { role: "alert" }
            },
            [
              _c("p", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.alertMessage) + ".")
              ]),
              _c(
                "span",
                {
                  staticClass: "absolute top-0 bottom-0 right-0 px-4 py-3",
                  on: { click: _vm.onClick }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "fill-current h-6 w-6 text-white",
                      attrs: {
                        role: "button",
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 20 20"
                      }
                    },
                    [
                      _c("title", [_vm._v("Close")]),
                      _c("path", {
                        attrs: {
                          d:
                            "M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }