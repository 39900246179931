export const CHANGE_PASSWORD = "changePassword";
export const CHECK_AUTH = "checkAuth";
export const CONFIRM_FORGOT_PASSWORD = "confirmForgotPassword";
export const CONFIRM_REGISTRATION = "confirmRegistration";
export const CONFIRM_REGISTRATION_RESEND = "confirmRegistrationResend";
export const FETCH_COMPANY_PAGE = "fetchCompanyPage";
export const FORGOT_PASSWORD = "forgotPassword";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const RESEND_FORGOT_PASSWORD = "resendForgotPassword";
export const REGISTER_COMPANY = "registerCompany";
export const REGISTER_STUDENT = "registerStudent";
export const SHOW_ALERT = "showAlert";
