var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "min-h-screen bg-white" },
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "flex flex-col flex-1 pt-12" },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }