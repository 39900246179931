import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";

import auth from "./auth.module";
import alert from "./alert.module";

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({ values: state.values }),
  storage: window.localStorage,
});
const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

const state = {
  /*companies: [
    { name: "Atlassian", values: ["typescript"] },
    { name: "Google", values: ["java", "sql"] },
    { name: "Deloitte", values: ["typescript", "java"] },
    { name: "Yahoo!", values: ["c#", "sql"] },
    { name: "Vandelay", values: ["c#", "java"] },
  ],*/
  skills: {
    languages: ["javascript", "c#", "java", "typescript", "sql"],
    cloud: ["amazon-web-services", "azure", "google-cloud"],
    frontend: ["reactjs", "angular", "vuejs", "knockoutjs"],
    bigdata: ["kafka", "spark", "snowflake", "neo4j"],
    culture: [
      "friday-drinks",
      "table-tennis",
      "hackathons",
      "diversity",
      "retros",
      "innovation",
    ],
    career: [
      "graduate-programme",
      "internship",
      "training-days",
      "career-path",
    ],
  },
  values: [],
};

const getters = {
  filteredCompanies(state) {
    if (state.values.length) {
      let filtered = [];

      state.companies.forEach((item) => {
        let intersection = item.values.filter((s) => state.values.includes(s));
        if (intersection.length > 0) {
          item.matchedValues = intersection;
          filtered.push(item);
        }
      });

      return filtered.sort(
        (a, b) => a.matchedValues.length < b.matchedValues.length
      );
    } else {
      return state.companies;
    }
  },
};

const mutations = {
  toggleSkill: (state, value) => {
    let index = state.values.indexOf(value);
    // item is in the array
    if (index >= 0) {
      state.values.splice(index, 1);
    } else {
      state.values.push(value);
    }
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  modules: [alert, auth],
  plugins: [logrocketPlugin, vuexLocal.plugin],
});
