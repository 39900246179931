import Storage from "@aws-amplify/storage"
import { Amplify } from "@aws-amplify/core";

const uploader = async (file, progress, error, options) => {
  // console.log(JSON.stringify(options))
  try{
    const storage = Storage.configure({
      "bucket": "graduated-storage",
      "region": "ap-southeast-2"
    });
    Amplify.register(storage);
    /*return Storage.put(file, 'Example content', {
      level: 'private',
    })*/
    return await Storage.put(file, 'Example content', {
      level: 'private',
    })
  } catch (err) {
    error('unable to upload file');
  }
}

export default uploader;
