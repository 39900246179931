var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "relative bg-gray-400 pt-8 pb-6 px-3" }, [
    _c("div", { staticClass: "container mx-auto max-w-screen-lg" }, [
      _c("div", { staticClass: "flex flex-wrap" }, [
        _vm._m(0),
        _c("div", { staticClass: "w-full lg:w-6/12 " }, [
          _c("div", { staticClass: "flex flex-wrap items-top mb-6" }, [
            _c("div", { staticClass: "w-full lg:w-4/12 px-4 ml-auto" }, [
              _c(
                "span",
                {
                  staticClass:
                    "block uppercase text-gray-600 text-sm font-semibold mb-2"
                },
                [_vm._v("Useful Links")]
              ),
              _c("ul", { staticClass: "list-unstyled" }, [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm",
                        attrs: { to: "/about-us" }
                      },
                      [_vm._v("About Us")]
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm",
                        attrs: { to: "/contact-us" }
                      },
                      [_vm._v("Contact Us")]
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm",
                        attrs: { to: "/terms" }
                      },
                      [_vm._v("Terms & Conditions")]
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm",
                        attrs: { to: "/privacy" }
                      },
                      [_vm._v("Privacy Policy")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticClass:
            "flex flex-wrap my-8 items-center md:justify-between justify-center"
        },
        [
          _c(
            "div",
            { staticClass: "w-full md:w-4/12 px-4 mx-auto text-center" },
            [
              _c(
                "div",
                { staticClass: "text-sm text-gray-600 font-semibold pb-8" },
                [
                  _vm._v(" Copyright © " + _vm._s(_vm.date) + " "),
                  _c("router-link", { attrs: { to: "/" } }, [
                    _vm._v("graduated.io")
                  ])
                ],
                1
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full lg:w-6/12 mb-1" }, [
      _c("h4", { staticClass: "text-3xl font-semibold" }, [
        _vm._v("Let's keep in touch!")
      ]),
      _c("h5", { staticClass: "text-lg mt-0 mb-2 text-gray-700" }, [
        _vm._v(" Find us on any of these networks. ")
      ]),
      _c("div", { staticClass: "my-3" }, [
        _c(
          "button",
          {
            staticClass:
              "bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2",
            attrs: { type: "button" }
          },
          [
            _c(
              "a",
              { attrs: { href: "https://www.linkedin.com/company/66325688" } },
              [_c("i", { staticClass: "fab fa-linkedin" })]
            )
          ]
        ),
        _c(
          "button",
          {
            staticClass:
              "bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2",
            attrs: { type: "button" }
          },
          [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.facebook.com/graduatedio-317354168936402"
                }
              },
              [_c("i", { staticClass: "fab fa-facebook-square" })]
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }