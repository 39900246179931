export default {
  aws_project_region: process.env.VUE_APP_AWS_REGION, // "ap-southeast-2",
  aws_cognito_identity_pool_id: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID, // "us-west-2:dbb07dc3-57ae-494a-9e25-bd820a31ef70",
  aws_cognito_region: process.env.VUE_APP_COGNITO_REGION, // "ap-southeast-2",
  aws_user_pools_id: process.env.VUE_APP_COGNITO_USER_POOL_ID, // "us-west-2_N5DJk1wz2",
  aws_user_pools_web_client_id: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID, // "63c32tn6k5q0iblmvlf1rgf4o0",
  oauth: {
    domain: process.env.VUE_APP_OAUTH_DOMAIN, // "auth.graduated.io",
    scope: [
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    responseType: "code",
    redirectSignIn: process.env.VUE_APP_OAUTH_REDIRECT_SIGN_IN, // "http://localhost:8080",
    redirectSignOut: process.env.VUE_APP_OAUTH_REDIRECT_SIGN_OUT, // "http://localhost:8080"
  },
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_APPSYNC_GRAPHQL_ENDPOINT, // "https://4duec5ps6ffxtgl6e2hrnnivge.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  aws_appsync_region: process.env.VUE_APP_AWS_REGION, // "ap-southeast-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket: process.env.VUE_APP_S3_STORAGE_NAME,
  aws_user_files_s3_bucket_region: process.env.VUE_APP_AWS_REGION,
  AWSKinesisFirehose: {
    region: process.env.VUE_APP_AWS_REGION, // "ap-southeast-2",
    bufferSize: 50,
    flushSize: 10,
    flushInterval: 10000,
    resendLimit: 5
  },
  AWSS3: {
    bucket: process.env.VUE_APP_S3_STORAGE_NAME, // "graduated-storage",
    region: process.env.VUE_APP_AWS_REGION, // "ap-southeast-2"
  }
}
