<template>
  <div
    class="w-full fixed top z-20"
    style="background-color:#44B244"
  >
    <nav
      class="flex items-center justify-between flex-wrap mx-auto max-w-screen-lg p-1"
    >
      <div class="flex items-center flex-no-shrink text-white mr-6">
        <router-link
          class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white"
          to="/"
        >
          <img
            width="100"
            src="/logos/graduated.png"
          >
        </router-link>
      </div>
      <div
        v-if="!isAuthenticated"
        class="block sm:hidden"
      >
        <button
          class="flex items-center px-3 py-2 border rounded text-white border-teal-light hover:text-white hover:border-white"
          @click="toggle"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        v-if="!isAuthenticated"
        class="w-full sm:flex sm:items-center sm:w-auto"
        :class="open ? 'block' : 'hidden'"
      >
        <div class="text-sm sm:flex-grow">
          <router-link
            to="/login"
            class="no-underline block mt-4 sm:inline-block sm:mt-0 text-white hover:text-white mr-4"
          >
            Login
          </router-link>
          <router-link
            to="/signup"
            class="no-underline block mt-4 sm:inline-block sm:mt-0 text-white hover:text-white mr-4"
          >
            Signup
          </router-link>
          <router-link
            to="/join"
            class="no-underline inline-block sm:inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-gray-900 hover:bg-white mt-4 sm:mt-0"
          >
            For Employers
          </router-link>
        </div>
      </div>
      <div
        v-if="isAuthenticated"
        class="group inline-block relative"
      >
        <button
          class="text-white font-semibold py-2 px-4 rounded inline-flex items-center hover:bg-gray-700 hover:text-white"
        >
          <span class="mr-1">{{ currentUserGivenName }}</span>
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </button>
        <ul
          class="absolute hidden text-gray-700 pt-1 group-hover:block right-0 z-20"
        >
          <li class="rounded">
            <a
              class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
              href="#"
              @click="signOut"
            >Sign out</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <!--<div
    class="bg-blue-500 text-white md:flex md:justify-between md:items-center"
  >
    <div class="flex items-center justify-between p-4">
      <router-link
        class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white"
        to="/"
        >graduated.io</router-link
      >

      <button @click="toggle" class="md:hidden">
        <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
          <path
            fill-rule="evenodd"
            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
          />
        </svg>
      </button>
    </div>

    <ul
      class="flex flex-col  bg-gray-700 px-4 py-2 md:flex md:flex-row md:bg-transparent"
      :class="open ? 'block' : 'hidden'"
    >
      <NavLink class="md:pl-2 md:pr-4" href="/">Home</NavLink>
      <NavLink class="md:px-4" href="/counter">Counter</NavLink>
      <NavLink class="md:px-4" href="/fetchdata">Fetch Data</NavLink>
    </ul>
  </div>-->
  <!--<nav class="bg-gray-900 px-2 py-1 md:flex md:justify-between md:items-center">
    <div class="flex justify-between">
      <router-link
        class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white"
        to="/"
        >graduated.io</router-link
      >
      <button @click="toggle" class="md:hidden">
        <svg
          class="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>

    <div
      v-if="!isAuthenticated"
      class="flex flex-col px-4 py-2 md:flex md:flex-row md:bg-transparent"
      :class="open ? 'block' : 'hidden'"
    >
      <router-link to="/login" class="text-white mr-4">
        Login
      </router-link>
      <router-link to="/signup" class="text-white mr-4">
        Signup
      </router-link>
      <router-link
        to="/join"
        class="bg-transparent hover:bg-white text-white hover:text-black border border-grey-400 hover:border-transparent py-1 px-2 rounded"
      >
        For Employers
      </router-link>
    </div>
  </nav>-->
  <!--<nav
    class="fixed top-0 bg-gray-900 z-10 w-full h-12 flex flex-wrap justify-between px-2 py-1"
  >
    <div
      class="container  max-w-screen-lg px-4 mx-auto flex flex-no-shrink items-center justify-between"
    >
      <div class="w-full  lg:w-auto lg:static lg:block lg:justify-start">
        <router-link
          class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white"
          to="/"
          >graduated.io</router-link
        >
      </div>
      <div class="sm:hidden">
        <button
          @click="toggle"
          class="flex items-center px-3 py-2 border rounded text-gray-lighter border-gray-light hover:text-white hover:border-white"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div v-if="!isAuthenticated" class="group inline-block relative">
        <router-link to="/login" class="text-white mr-4">
          Login
        </router-link>
        <router-link to="/signup" class="text-white mr-4">
          Signup
        </router-link>
        <button
          v-on:click="onClickForEmployers"
          class="bg-transparent hover:bg-white text-white hover:text-black border border-grey-400 hover:border-transparent py-1 px-2 rounded"
        >
          For Employers
        </button>
      </div>
      <div v-if="isAuthenticated" class="group inline-block relative">
        <button
          class="text-white font-semibold py-2 px-4 rounded inline-flex items-center hover:bg-gray-700 hover:text-white"
        >
          <span class="mr-1">{{ currentUserGivenName }}</span>
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </button>
        <ul
          class="absolute hidden text-gray-700 pt-1 group-hover:block right-0 z-20"
        >
          <li class="rounded">
            <a
              class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
              v-on:click="signOut"
              href="#"
              >Sign out</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>-->
</template>

<script>
//https://jsfiddle.net/adamwathan/o1uotjxk/
import { mapGetters } from "vuex";
import { LOGOUT } from "../store/actions.type";

export default {
  name: "Navbar",
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserGivenName"]),
  },
  methods: {
    onClickForEmployers() {
      this.$router.push({ name: "join" });
    },
    signOut() {
      this.$store.dispatch(LOGOUT);
    },
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
