<template>
  <div>
    <Alert />
    <Navbar />
    <main>
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script>
import Alert from "@/components/Alert";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
export default {
  name: "DefaultLayout",
  components: {
    Alert,
    Footer,
    Navbar,
  },
};
</script>
