<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :data-type="context.type"
    class="relative"
  >
    <input
      v-model="context.model"
      type="text"
      v-bind="context.attributes"
      autocomplete="no"
      class="mb-0"
      @keydown.enter.prevent="context.model = selection.label"
      @keydown.down.prevent="increment"
      @keydown.up.prevent="decrement"
      @blur="context.blurHandler"
      @input="fetch"
    >
    <ul
      v-if="filteredOptions.length"
      class="formulate-input-dropdown border absolute rounded -mt-1 bg-white w-full overflow-hidden"
    >
      <li
        v-for="(option, index) in filteredOptions"
        :key="option.name"
        :data-is-selected="selection && selection.value === option.value"
        class="px-3 py-2 cursor-pointer hover:bg-gray-200"
        @mouseenter="selectedIndex = index"
        @click="context.model = selection.name"
        v-text="option.name"
      />
    </ul>
  </div>
</template>

<script>

import { API, graphqlOperation } from "@aws-amplify/api";
import { lookupUniversity } from "@/graphql/queries";

export default {
  name: "AutoComplete",
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options :[],
      selectedIndex: 0
    }
  },
  computed: {
    model () {
      return this.context.model
    },
    selection () {
      if (this.filteredOptions[this.selectedIndex]) {
        return this.filteredOptions[this.selectedIndex]
      }
      return false
    },
filteredOptions () {
  console.log(this.options);
      if (Array.isArray(this.options) && this.context.model) {
        const isAlreadySelected = this.options.find(option => option.name === this.context.model)
        if (!isAlreadySelected) {
          return this.options
            .filter(option => option.name.toLowerCase().includes(this.context.model.toLowerCase()))
        }
      }
      return []
    }
  },
  watch: {
    model () {
      this.selectedIndex = 0
    }
  },
  methods: {
    async fetch() {
      const result = await API.graphql(
          graphqlOperation(lookupUniversity, { input: this.context.model }))

console.log(result);
        //if( result?.data?.lookupUniversity)
        this.options = result?.data?.lookupUniversity;
    },
    increment () {
      const length = this.filteredOptions.length
      if (this.selectedIndex + 1 < length) {
        this.selectedIndex++
      } else {
        this.selectedIndex = 0
      }
    },
    decrement () {
      const length = this.filteredOptions.length
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--
      } else {
        this.selectedIndex = length - 1
      }
    }
  },
   /*created() {
    const results =  API.graphql(
          graphqlOperation(lookupUniversity, { input: 'university of new' })
        );

        console.log(results)
  }*/
}
</script>