import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import en from "vee-validate/dist/locale/en";
import * as rules from "vee-validate/dist/rules";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import uploader from '@/common/uploader';
//import { Amplify } from "@aws-amplify/core";
//import Analytics, { AWSKinesisFirehoseProvider } from "@aws-amplify/analytics";
//import { API } from "@aws-amplify/api";
import VueFormulate from '@braid/vue-formulate';
import VueFormulateExtended from 'vue-formulate-extended';
//import AuthConfig from "@/aws-exports";
//import ApiConfig from "./amplifyconfiguration.json";
import AutoComplete from './components/AutoComplete/AutoComplete';
//import * as Sentry from "@sentry/browser";
//import { Vue as VueIntegration } from "@sentry/integrations";
//import { Integrations } from "@sentry/tracing";

import "@/lib/amplify";

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

Vue.component('AutoComplete', AutoComplete)
Vue.use(VueFormulate, {
  classes: {
    outer: 'mb-4',
    label: 'leading-6 font-light text-sm',
    input (context) {
      //console.log(context)
      switch (context.classification) {
        case 'textarea':
          return 'font-light font-sans text-sm leading-5 w-full rounded-sm border flex-1 p-2 m-1 bg-transparent h-64 outline-none'
        case 'button':
          return 'sm:ml-3 px-4 py-2 rounded bg-orange-500 text-white hover:bg-orange-600 text-base leading-6 font-medium text-white sm:text-sm sm:leading-5 border border-transparent hover:bg-orange-500 focus:outline-none focus:border-orange-700 ease-in-out duration-150'
        case 'box':
          return ' form-radio appearance-none checked:bg-gray-900 checked:border-transparent'
        case 'select': 
          return 'form-select w-full'
        case 'radio':
          return ''
        default:
          return 'border border-gray-400 rounded px-3 py-2 leading-6 border-box w-full mb-1'
      }
    },
    help: 'text-xs mb-1 text-gray-600',
    error: 'text-red-700 text-xs mb-1'
  },
  library: {
    autocomplete: {
      classification: 'text',
      component: 'AutoComplete'
    }
  },
  plugins: [
    VueFormulateExtended({
      features: {
        formEvents: true,
      },
    }),
  ],
  uploader: uploader
});

/*
Sentry.init({
  dsn:
    "https://13f8d767a0174aa5a2f82c9d555406f9@o442663.ingest.sentry.io/5415032",
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
      logErrors: true,
    }),
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1,
});
*/

//Analytics.addPluggable(new AWSKinesisFirehoseProvider());
//Auth.configure(AuthConfig);
//Amplify.configure(ApiConfig);


/*let myAppConfig = {
  // ...
  aws_appsync_graphqlEndpoint:
    "https://4duec5ps6ffxtgl6e2hrnnivge.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-kfhmmbvwqvcbvo2ffqjeqgxdem",
  // ...
};*/

//Amplify.configure(myAppConfig);

import LogRocket from "logrocket";
if (process.env.NODE_ENV === "production") {
  LogRocket.init("6i1ihe/graduated");
}

//import "@/assets/styles/vue-formulate-snow.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue-loading-overlay/dist/vue-loading.css";

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
extend("verify_password", {
  validate: (value) => {
    const strongRegex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*,<>:;|_`~-])(?=.{8,})/
    );
    return strongRegex.test(value);
  },
});

localize("en", en);
localize({
  en: {
    messages: {
      verify_password:
        "Use 8 or more characters with a mix of letters, number and symbols.",
    },
  },
});

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VueCompositionApi);
Vue.config.productionTip = false;

console.log(process.env.VUE_APP_APPSYNC_GRAPHQL_ENDPOINT);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
