var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "relative",
      class:
        "formulate-input-element formulate-input-element--" + _vm.context.type,
      attrs: { "data-type": _vm.context.type }
    },
    [
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.context.model,
                expression: "context.model"
              }
            ],
            staticClass: "mb-0",
            attrs: { type: "text", autocomplete: "no" },
            domProps: { value: _vm.context.model },
            on: {
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  _vm.context.model = _vm.selection.label
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.increment($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.decrement($event)
                }
              ],
              blur: _vm.context.blurHandler,
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.context, "model", $event.target.value)
                },
                _vm.fetch
              ]
            }
          },
          "input",
          _vm.context.attributes,
          false
        )
      ),
      _vm.filteredOptions.length
        ? _c(
            "ul",
            {
              staticClass:
                "formulate-input-dropdown border absolute rounded -mt-1 bg-white w-full overflow-hidden"
            },
            _vm._l(_vm.filteredOptions, function(option, index) {
              return _c("li", {
                key: option.name,
                staticClass: "px-3 py-2 cursor-pointer hover:bg-gray-200",
                attrs: {
                  "data-is-selected":
                    _vm.selection && _vm.selection.value === option.value
                },
                domProps: { textContent: _vm._s(option.name) },
                on: {
                  mouseenter: function($event) {
                    _vm.selectedIndex = index
                  },
                  click: function($event) {
                    _vm.context.model = _vm.selection.name
                  }
                }
              })
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }