import Vue from "vue";
import Router from "vue-router";
import Auth from "@aws-amplify/auth";
import { LOGOUT } from "@/store/actions.type";
import AppLayout from "@/layouts/AppLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";

Vue.use(Router);

const routes = [
  {
    name: "landing",
    path: "/",
    component: () => import("@/views/Landing"),
    meta: { title: "graduated.io", auth: false, layout: DefaultLayout },
  },
  {
    name: "join",
    path: "/join",
    component: () => import("@/views/Join"),
    meta: { auth: false, layout: DefaultLayout },
  },
  {
    name: "company",
    path: "/company/:id",
    component: () => import("@/views/Company"),
    meta: { auth: false, layout: DefaultLayout },
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("@/views/Profile"),
    meta: { auth: true, layout: AppLayout },
  },
  {
    name: "search",
    path: "/search",
    component: () => import("@/views/Search"),
    meta: { auth: true, layout: AppLayout },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/auth/Login"),
    meta: { title: "Login", auth: false, layout: AuthLayout },
  },
  {
    path: "/logout",
    beforeEnter() {
      this.$store.dispatch(LOGOUT);
    },
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("@/views/auth/Signup"),
    meta: { title: "Signup", auth: false, layout: AuthLayout },
  },
  {
    name: "forgotPassword",
    path: "/forgot",
    component: () => import("@/views/auth/ForgotPassword"),
    meta: { title: "Forgot password", auth: false, layout: AuthLayout },
  },
  {
    name: "confimForgotPassword",
    path: "/confirm-forgot-password",
    component: () => import("@/views/auth/ConfirmForgotPassword"),
    meta: { title: "Confirm forgot password", auth: false, layout: AuthLayout },
  },
  {
    name: "confirmRegistration",
    path: "/confirm",
    component: () => import("@/views/auth/Confirm"),
    meta: { title: "Confirm registration", auth: false, layout: AuthLayout },
  },
  {
    name: "changePassword",
    path: "/change-password",
    component: () => import("@/views/auth/ChangePassword"),
    meta: { title: "Change password", auth: true, layout: AuthLayout },
  },
  {
    name: "about",
    path: "/about-us",
    component: () => import("@/views/About"),
    meta: { title: "About us", auth: false, layout: DefaultLayout },
  },
  {
    name: "contact",
    path: "/contact-us",
    component: () => import("@/views/Contact"),
    meta: { title: "Contact us", auth: false, layout: DefaultLayout },
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("@/views/Terms"),
    meta: { title: "Terms & Conditions", auth: false, layout: DefaultLayout },
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("@/views/Privacy"),
    meta: { title: "Privacy Policy", auth: false, layout: DefaultLayout },
  },
  {
    path: "/404",
    component: () => import("@/views/NotFound"),
    meta: { layout: DefaultLayout },
  },
  { path: "*", redirect: "/404" },
];

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});

const isAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (_) {
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  // Use the page's router title to name the page
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  // is there a meta and auth attribute?
  if (to.meta && to.meta.auth !== undefined) {
    const isAuth = await isAuthenticated();
    // if the page requires auth
    if (to.meta.auth) {
      // and we are authenticated?
      if (isAuth) {
        next(); // route normally
        return;
      }
      // otherwise off to the sign in page
      router.push({ name: "login" });
      return;
    }
    // otherwise are we already authenticated?
    //if (isAuth) {
    // yes we are, so off to dashboard
    //  router.push({ name: "dashboard" });
    //  return;
    //}
    next(); // route normally
    return;
  }
  next(); // route normally
  return;
});

export default router;
