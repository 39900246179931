<template>
  <div class="flex min-h-screen bg-white">
    <Alert />
    <div class="flex flex-1 flex-col">
      <Navbar />
      <div class="flex flex-col justify-center flex-1 sm:px-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert";
import Navbar from "@/components/Navbar";

export default {
  name: "AuthLayout",
  components: {
    Alert,
    Navbar,
  },
};
</script>
