import Auth from "@aws-amplify/auth";
import Amplify, { ConsoleLogger } from "@aws-amplify/core";
import {
  LOGIN,
  LOGOUT,
  REGISTER_STUDENT,
  REGISTER_COMPANY,
  CONFIRM_REGISTRATION,
  CONFIRM_REGISTRATION_RESEND,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  RESEND_FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  SHOW_ALERT,
} from "./actions.type";
import {
  CONFIRMATION_EMAIL,
  PURGE_AUTH,
  SET_AUTH_STATUS,
  SET_USER,
} from "./mutations.type";
import router from "@/router";

const Logger = Amplify.Logger;
Logger.LOG_LEVEL = "INFO"; // show detailed logs from aplify library
const logger = new Logger("store:auth");

const state = {
  user: null,
  authenticationStatus: null,
  confirmation: null
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentUserGivenName(state) {
    return state.user.signInUserSession.idToken.payload.given_name;
  },
  isAuthenticated(state) {
    return state.user ? true : false;
  },
  isUserConfirmed(state) {
    if (state.authenticationStatus === null) return undefined;
    return state.authenticationStatus.userConfirmed;
  },
  confirmationEmail(state) {
    if (state.authenticationStatus !== null) {
      return {
        email: state.authenticationStatus.user.username,
        obfuscatedEmail:
          state.authenticationStatus.codeDeliveryDetails.Destination,
      };
    } else
      return {
        email: state.confirmation?.email,
        obfuscatedEmail: state.confirmation?.obfuscatedEmail,
      };
  },
};

const mutations = {
  [SET_USER](state, payload) {
    state.user = payload.user;
  },
  [SET_AUTH_STATUS](state, payload) {
    state.authenticationStatus = payload;
  },
  [PURGE_AUTH](state) {
    state.user = null;
  },
  [CONFIRMATION_EMAIL](state, payload) {
    state.confirmation = payload;
  },
};

const actions = {
  async [LOGIN](context, params) {
    const { username, password } = params;
    try {
      const user = await Auth.signIn(username, password);
      context.commit(SET_USER, { user });
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        context.commit(CONFIRMATION_EMAIL, {
          email: username,
          obfuscatedEmail: username,
        });
        router.push({ name: "confirmRegistration" });
      }
      console.log(JSON.stringify(error));
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
      logger.error(error);
    }
  },

  async [LOGOUT](context) {
    try {
      await Auth.signOut();
    } catch (error) {
      logger.error(error);
    }
    context.commit(PURGE_AUTH);
    router.push({ name: "landing" });
  },

  async [REGISTER_STUDENT](context, params) {
    try {
      const result = await Auth.signUp(params);
      context.commit(SET_AUTH_STATUS, result);
    } catch (error) {
      logger.error(error);
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
    }
  },

  async [REGISTER_COMPANY](context, params) {
    try {
      await Auth.signUp(params);
    } catch (error) {
      logger.error(error);
    }
  },

  async [CONFIRM_REGISTRATION](context, params) {
    try {
      await Auth.confirmSignUp(params.username, params.code);
    } catch (error) {
      logger.error(error);
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
    }
  },

  async [CONFIRM_REGISTRATION_RESEND](context, params) {
    try {
      await Auth.resendSignUp(params.username);
    } catch (error) {
      logger.error(error);
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
    }
  },

  async [FORGOT_PASSWORD](context, params) {
    try {
      await Auth.forgotPassword(params.username);
    } catch (error) {
      logger.error(error);
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
    }
  },

  async [CONFIRM_FORGOT_PASSWORD](context, params) {
    try {
      await Auth.forgotPasswordSubmit(
        params.username,
        params.code,
        params.code
      );
    } catch (error) {
      logger.error(error);
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
    }
  },

  async [RESEND_FORGOT_PASSWORD](context, params) {
    try {
      await Auth.passwordResetResend(params.username);
    } catch (error) {
      logger.error(error);
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
    }
  },

  async [CHANGE_PASSWORD](context, params) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        params.currentPassword,
        params.newPassword
      );
    } catch (error) {
      logger.error(error);
      await context.dispatch(SHOW_ALERT, {
        message: error.message,
      });
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
