<template>
  <component :is="this.$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<script>
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import Alert from "@/components/Alert";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import { SET_USER } from "@/store/mutations.type";

export default {
  name: "App",
  components: {
    Alert,
    Footer,
    Navbar,
  },
  data() {
    return {
      signedIn: false,
    };
  },
  beforeCreate() {
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          //this.$store.commit(SET_USER, { user: data });
          Auth.currentAuthenticatedUser()
            .then((user) => {
              this.$store.commit(SET_USER, { user });
              console.log('push profile');
              this.$router.push({ name: "profile" });
            })
            .catch(() => console.log("Not signed in"));
          break;
        //case "signOut":
        //  this.setState({ user: null });
        //  break;
        //}
      }
    });
    console.log("currentAuthenticatedUser");
    Auth.currentAuthenticatedUser()
      .then((user) => this.$store.commit(SET_USER, { user }))
      .catch(() => console.log("Not signed in"));
  },
};
</script>

<style src="./assets/styles/index.css">
