<template>
  <div v-if="alertVisible" class="fixed w-full z-20">
    <div class="relative">
      <div
        class="items-center bg-orange-500 h-12 text-white text-sm font-bold px-4 py-3"
        role="alert"
      >
        <p class="text-center">{{alertMessage}}.</p>
        <span v-on:click="onClick" class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            class="fill-current h-6 w-6 text-white"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path
              d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Alert",
  computed: {
    ...mapGetters(["alertMessage", "alertVisible"])
  },
  methods: {
    async onClick() {
      this.show = false;
    }
  }
};
</script>